import { createGlobalStyle } from "styled-components";

import Monst from "../Assets/Fonts/Montserrat-VariableFont_wght.ttf";
import Dancing from "../Assets/Fonts/DancingScript-VariableFont_wght.ttf";
import Lobster from "../Assets/Fonts/Lobster-Regular.ttf";
import RailWay from "../Assets/Fonts/Raleway-VariableFont_wght.ttf";

const FontStyles = createGlobalStyle`

@font-face {
    font-family: 'Montseratt';
    src: url(${Monst});
}
@font-face {
    font-family: 'DancingScript';
    src: url(${Dancing});
}

@font-face {
    font-family: 'Lobster';
    src: url(${Lobster});
}

@font-face {
    font-family: 'Railway';
    src: url(${RailWay});
}
`;

export default FontStyles;
