import styled from "styled-components";

export const Container = styled.div`
  position: absolute;
  width: 100%;
  height: auto;
  background-color: ${(props) => props.theme.Menu_color};
  z-index: 9;
  /* top: 50px; */
  transition: all 0.2s;
  padding-top: 60px;
`;

export const Menu = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${(props) => props.theme.font_color};
  padding: 10px 0;
  border-top: 1px solid rgb(255, 255, 255, 0.2);
`;

export const Ref = styled.i`
  padding: 30px 20px;
  font-size: x-large;
  width: 100%;
  position: relative;
  letter-spacing: 2px;
  color: ${(props) => props.theme.font_color};
  cursor: pointer;
`;

export const Line = styled.hr`
  margin: 0 auto;
  width: 70%;
  border: 0.5px solid ${(props) => props.theme.HR_color};
`;

export const Icon = styled.div`
  display: inline-block;
  position: absolute;
  left: 28%;
  top: 35%;

  @media (max-width: 410px) {
    left: 26%;
  }
  @media (max-width: 390px) {
    left: 25%;
  }
`;
