import styled from "styled-components";

export const Container = styled.div`
  height: 100vh;
  background-color: ${(props) => props.theme.skills_page_color};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  @media (max-width: 1000px) {
    height: auto;
  }
`;

export const SkillsTitle = styled.p`
  font-size: 50px;
  color: ${(props) => props.theme.font_color};
  letter-spacing: 2px;
  font-family: "Railway";
  transition: all 0.8s;

  @media (max-width: 1400px) {
    margin-top: 6rem;
  }
`;

export const FrontAndBackContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: auto;
  @media (max-width: 1000px) {
    flex-direction: column;
  }
  @media (max-width: 390px) {
    width: 90%;
  }
`;
