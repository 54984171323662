import React from "react";
import { useSelector } from "react-redux";
import { FrontSkillsData } from "../../Data/Skills";
import {
  Container,
  Div1,
  Divider,
  Language,
  Level,
  Skill,
  Title,
} from "./styles";

export default function FrontendSkills() {
  const color = useSelector((state) => state.levelColor.value);
  return (
    <Divider>
      <Title>Frontend</Title>
      <Container>
        {FrontSkillsData.map((el, key) => (
          <Skill key={key}>
            <div>{el.icon && <el.icon size={20} color={color} />}</div>
            <Div1>
              <Language>{el.language}</Language>
              <Level>{el.level}</Level>
            </Div1>
          </Skill>
        ))}
      </Container>
    </Divider>
  );
}
