import React from "react";
import MyImage from "../../Components/MyImage/MyImage";
import {
  AboutContainer,
  AboutHeader,
  AboutMeDesc,
  Container,
  LeftContent,
  RightContent,
} from "./styles";

export default function About() {
  return (
    <Container id="about">
      <AboutContainer>
        <LeftContent>
          <MyImage />
        </LeftContent>
        <RightContent>
          <AboutHeader>About</AboutHeader>
          <AboutMeDesc>
            My name is Mohamed Agbaria and I am an Information Systems graduate
            from the university of Haifa with a specialization in development. I
            have also completed an intensive full-stack course, where I learned
            and gained expertise in building web-based apps. During my
            bachelor's degree, I was engaged in various projects related to
            different fields such as Machine Learning, Artificial Intelligence,
            Big Data, Software Engineering, and more. Through these experiences,
            I have developed both soft and technical skills. I have a strong
            desire to continuously learn and improve my skills using every
            available opportunity.
            <br />
            <br />
            <span style={{ fontWeight: 500 }}>Hobbies:</span> <br />{" "}
            <span style={{ opacity: 0.5 }}>
              Football , Swimming , Reading , Video Games
            </span>
            <br />
            <br />
            <span style={{ fontWeight: 500 }}>Spoken Languages:</span>
            <br />
            <span style={{ opacity: 0.5 }}>
              Arabic , Hebrew , English , German
            </span>
          </AboutMeDesc>
        </RightContent>
      </AboutContainer>
    </Container>
  );
}
