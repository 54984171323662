import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setBurgerMenuClicked } from "../../Redux/LevelColor";
import {
  BottomLine,
  Burger,
  CloseX,
  Container,
  MiddleLine,
  TopLine,
} from "./styles";

export default function BurgetMenu() {
  // const [isBurgerClicked, setIsBurgerClicked] = useState(false);
  const dispatch = useDispatch();
  const isBurgerClicked = useSelector(
    (state) => state.levelColor.isBurgerMenuClicked
  );
  function ButtonClicked() {
    // setIsBurgerClicked(!isBurgerClicked);
    if (isBurgerClicked) {
      dispatch(setBurgerMenuClicked(false));
    } else {
      dispatch(setBurgerMenuClicked(true));
    }
  }
  return (
    <Container onClick={ButtonClicked}>
      {isBurgerClicked && <CloseX>X</CloseX>}
      {!isBurgerClicked && (
        <Burger>
          <TopLine></TopLine> <MiddleLine></MiddleLine>
          <BottomLine></BottomLine>
        </Burger>
      )}
    </Container>
  );
}
