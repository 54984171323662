import styled from "styled-components";

export const Container = styled.div`
  height: 100vh;
  width: 100%;
  min-height: 100%;
  background-color: ${(props) => props.theme.portfolio_color};
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: all 0.8s;
  justify-content: center;

  @media (max-width: 1350px) {
    height: auto;
  }
`;

export const ProjectsContainer = styled.div`
  width: 100%;
  display: flex;
  margin-top: 4rem;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;

  @media (max-width: 1350px) {
    margin-bottom: 2rem;
  }

  @media (max-width: 1150px) {
    width: 70%;
    flex-direction: column;
    align-items: center;
  }
  @media (max-width: 700px) {
    width: 80%;
  }
  @media (max-width: 600px) {
    width: 90%;
  }
  @media (max-width: 530px) {
    width: 100%;
  }
`;

export const Title = styled.p`
  font-size: 40px;
  color: ${(props) => props.theme.font_color};
  letter-spacing: 2px;
  /* margin-top: 4rem; */
  transition: all 0.8s;
  font-family: "Railway";

  @media (max-width: 1350px) {
    margin-top: 10%;
  }
`;
