import styled from "styled-components";
export const ImageBackground = styled.div`
  background-color: ${(props) => props.theme.secondary_color};
  width: 480px;
  height: 600px;
  transform-style: preserve-3d;
  position: relative;
  z-index: 5;
  transition: all 0.8s;
  /* overflow: hidden; */

  &:hover::before {
    height: 500px;
    top: 0px;
    left: 0px;
    width: 400px;
  }

  &:hover::after {
    height: 600px;
    top: 0px;
    left: 0px;
    width: 400px;
  }
  &::before {
    content: "";
    transform: translateZ(-1px);

    background-color: ${(props) => props.theme.about_secondBackgroundImage};
    position: absolute;
    width: 500px;
    height: 500px;
    left: -70px;
    top: 50px;
    transition: all 1s;
    z-index: 0;
  }

  &::after {
    content: "";
    transform: translateZ(-1px);

    background-color: ${(props) => props.theme.about_firstBackgroundImage};
    position: absolute;
    width: 500px;
    height: 550px;
    left: -40px;
    top: 25px;
    transition: all 1.5s;
    z-index: 0;
  }

  @media (max-width: 1200px) {
    width: 450px;
    height: 580px;

    &::after {
      width: 450px;
      height: 510px;
    }

    &::before {
      width: 450px;
      height: 450px;
    }

    &:hover::after {
      height: 580px;
    }
  }

  @media (max-width: 1100px) {
    margin: 0 auto;
  }
  @media (max-width: 800px) {
    width: 400px;
    height: 550px;

    &::after {
      width: 400px;
      height: 500px;
    }

    &::before {
      width: 400px;
      height: 440px;
    }

    &:hover::after {
      height: 550px;
    }
  }

  @media (max-width: 580px) {
    width: 380px;
    height: 520px;

    &::after {
      width: 380px;
      height: 470px;
    }

    &::before {
      width: 380px;
      height: 420px;
    }

    &:hover::after {
      width: 380px;
      height: 520px;
    }
    &:hover::before {
      width: 380px;
      height: 520px;
    }
  }

  @media (max-width: 540px) {
    width: 330px;
    height: 450px;

    &::after {
      width: 330px;
      height: 400px;
    }

    &::before {
      width: 330px;
      height: 350px;
    }

    &:hover::after {
      width: 330px;
      height: 450px;
    }
    &:hover::before {
      width: 330px;
      height: 450px;
    }
  }

  @media (max-width: 500px) {
    &::after {
      left: -20px;
    }

    &::before {
      left: -40px;
    }
  }

  @media (max-width: 430px) {
    width: 300px;
    height: 410px;

    &::after {
      width: 300px;
      height: 365px;
    }

    &::before {
      width: 300px;
      height: 320px;
    }

    &:hover::after {
      width: 300px;
      height: 410px;
    }
    &:hover::before {
      width: 300px;
      height: 410px;
    }
  }
`;

export const MyPic = styled.img`
  width: 100%;
`;

export const PictureContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
`;
