import {
  FaBootstrap,
  FaCss3,
  FaCss3Alt,
  FaHtml5,
  FaJava,
  FaJs,
  FaNodeJs,
  FaPhp,
  FaPython,
  FaReact,
} from "react-icons/fa";
import {
  SiMysql,
  SiCsswizardry,
  SiJquery,
  SiTypescript,
  SiMongodb,
  SiCplusplus,
} from "react-icons/si";

export var FrontSkillsData = [];

FrontSkillsData.push({ language: "HTML", level: "Advanced", icon: FaHtml5 });
FrontSkillsData.push({
  language: "CSS",
  level: "Advanced",
  icon: SiCsswizardry,
});
FrontSkillsData.push({ language: "React", level: "Advanced", icon: FaReact });
FrontSkillsData.push({ language: "Javascript", level: "Advanced", icon: FaJs });
FrontSkillsData.push({
  language: "Typescript",
  level: "Intermediate",
  icon: SiTypescript,
});
FrontSkillsData.push({
  language: "jQuery",
  level: "Intermediate",
  icon: SiJquery,
});
FrontSkillsData.push({
  language: "Bootstrap",
  level: "Intermediate",
  icon: FaBootstrap,
});

export var BackSkillsData = [];
BackSkillsData.push({ language: "Java", level: "Advanced", icon: FaJava });
BackSkillsData.push({ language: "Python", level: "Advanced", icon: FaPython });
BackSkillsData.push({
  language: "Node.js",
  level: "Intermediate",
  icon: FaNodeJs,
});
BackSkillsData.push({ language: "PHP", level: "Intermediate", icon: FaPhp });
BackSkillsData.push({ language: "MySql", level: "Advanced", icon: SiMysql });
BackSkillsData.push({
  language: "MongoDB",
  level: "Intermediate",
  icon: SiMongodb,
});
BackSkillsData.push({
  language: "C",
  level: "Intermediate",
  icon: SiCplusplus,
});

// export default { FrontSkillsData, BackSkillsData };
