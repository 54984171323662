import { FaEnvelope, FaGithub, FaLinkedin } from "react-icons/fa";
import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  min-height: 100vh;
  background-color: ${(props) => props.theme.contact_page_color};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  transition: all 0.8s;

  @media (max-width: 1200px) {
    height: auto;
  }
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  flex-wrap: wrap;
  margin-left: 2rem;

  @media (max-width: 1200px) {
    margin: auto;
  }
`;

export const Title = styled.p`
  color: ${(props) => props.theme.font_color};
  font-size: 44px;
  letter-spacing: 2px;
  font-weight: 600;
  transition: all 0.8s;
  font-family: "Railway";

  @media (max-width: 1200px) {
    margin-top: 5rem;
  }
`;

export const ThanksSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
`;
export const Section = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;

  @media (max-width: 1200px) {
    flex-direction: column;
    align-items: center;
  }
`;
export const LeftContainer = styled.div`
  position: relative;
  @media (max-width: 1200px) {
    margin-bottom: 4rem;
  }
`;

export const ThanksText = styled.div`
  color: ${(props) => props.theme.font_color};
  font-size: 70px;
  letter-spacing: 2px;
  width: 100%;

  @media (max-width: 1500px) {
    font-size: 65px;
  }

  @media (max-width: 1400px) {
    font-size: 60px;
  }
  @media (max-width: 1200px) {
    font-size: 50px;
  }
  @media (max-width: 1050px) {
    font-size: 45px;
  }
  @media (max-width: 900px) {
    font-size: 40px;
  }
  @media (max-width: 750px) {
    font-size: 50px;
    width: 70%;
  }
  @media (max-width: 500px) {
    font-size: 40px;
  }
  @media (max-width: 400px) {
    font-size: 36px;
  }
`;
export const Text = styled.div`
  color: ${(props) => props.theme.font_color};
  font-size: 62px;
  letter-spacing: 1px;
  width: 600px;

  @media (max-width: 1200px) {
    text-align: center;
  }
  @media (max-width: 800px) {
    font-size: 50px;
  }
  @media (max-width: 650px) {
    font-size: 36px;
    width: 98%;
  }
  @media (max-width: 600px) {
    font-size: 36px;
    width: 90%;
    margin: 0 auto;
  }
`;

export const MyStyledLabel = styled.span`
  color: ${(props) => props.theme.secondary_color};
  font-weight: 600;
  transition: all 0.8s;
  font-family: ${(props) => (props.primary ? "DancingScript" : "Railway")};
`;

export const FormTitle = styled.div`
  color: ${(props) => props.theme.project_desc_color};
  font-size: large;
  letter-spacing: 2px;
  font-family: cursive;
  font-weight: 100;
  transition: all 0.8s;
`;
export const SendMessageButton = styled.button`
  padding: 10px 30px;
  font-size: 26px;
  border-radius: 15px;
  width: 420px;
  cursor: pointer;
  margin-top: 20px;
  background: ${(props) => props.theme.contact_button_color};
  position: relative;
  border: none;
  overflow: hidden;
  box-shadow: 5px 5px 10px 2px rgb(0, 0, 0, 0.2);
  transition: all 0.5s;
  z-index: 5;
  &::before {
    content: "";
    width: 100%;
    height: 0%;
    left: 0;
    top: 0;
    background-color: ${(props) => props.theme.CV_hover};
    position: absolute;
    transition: all ease-in 0.1s;
    z-index: -2;
  }

  &:hover {
    transform: scale(0.98);
  }
  &:hover::before {
    height: 100%;
  }

  @media (max-width: 600px) {
    width: 370px;
  }

  @media (max-width: 450px) {
    width: 340px;
  }
`;

export const FullnameField = styled.input`
  width: 400px;
  height: 40px;
  border-radius: 15px;
  display: block;
  font-size: large;
  padding-left: 15px;
  margin: 10px 0px;
  outline: none;
  transition: all 0.5s;
  &:focus {
    border: solid 2px ${(props) => props.theme.secondary_color};
  }

  @media (max-width: 600px) {
    width: 350px;
  }
  @media (max-width: 450px) {
    width: 320px;
  }
`;

export const EmailField = styled.input`
  width: 400px;
  height: 40px;
  border-radius: 15px;
  display: block;
  padding-left: 15px;
  font-size: large;
  margin: 10px 0px;
  outline: none;
  transition: all 0.5s;
  &:focus {
    border: solid 2px ${(props) => props.theme.secondary_color};
  }
  @media (max-width: 600px) {
    width: 350px;
  }
  @media (max-width: 450px) {
    width: 320px;
  }
`;

export const MailImage = styled.img`
  width: 30%;
  position: absolute;
  right: 100px;
`;
export const MessageField = styled.textarea`
  display: block;
  width: 400px;
  border-radius: 15px;
  height: 200px;
  padding-left: 15px;
  font-size: large;
  margin: 10px 0px;
  outline: none;
  transition: all 0.5s;
  &:focus {
    border: solid 2px ${(props) => props.theme.secondary_color};
  }
  @media (max-width: 600px) {
    width: 350px;
  }
  @media (max-width: 450px) {
    width: 320px;
  }
`;

export const Footer = styled.div`
  @media (max-width: 1200px) {
    margin: 1rem 0;
  }
`;

export const MailLogo = styled(FaEnvelope)`
  color: ${(props) => props.theme.font_color};
  margin: 0px 15px;
  cursor: pointer;
  transition: all 0.6s;
  &:hover {
    transform: scale(1.1);
  }
`;

export const GitHubLogo = styled(FaGithub)`
  color: ${(props) => props.theme.font_color};
  margin: 0px 15px;
  cursor: pointer;
  transition: all 0.6s;
  &:hover {
    transform: scale(1.1);
  }
`;

export const LinkedInLogo = styled(FaLinkedin)`
  color: ${(props) => props.theme.font_color};
  margin: 0px 15px;
  cursor: pointer;
  transition: all 0.6s;
  &:hover {
    transform: scale(1.1);
  }
`;

export const LineInBetween = styled.hr`
  width: 60%;
  border-color: ${(props) => props.theme.skills_title_underline_color};

  @media (max-width: 1200px) {
    margin-top: 2rem;
  }
`;

export const Signature = styled.p`
  margin: 0;
  margin-top: 70px;
  font-size: 40px;
  font-family: "DancingScript";
  text-align: right;

  @media (max-width: 1200px) {
    font-size: 35px;
  }
  @media (max-width: 900px) {
    font-size: 30px;
  }
  @media (max-width: 750px) {
    font-size: 28px;
    text-align: center;
  }
  @media (max-width: 500px) {
    font-size: 25px;
  }
`;
export const Text2 = styled.p`
  margin: 0;
  font-size: 90px;
  font-family: "DancingScript";

  @media (max-width: 1200px) {
    text-align: center;
  }
  @media (max-width: 800px) {
    font-size: 64px;
  }
  @media (max-width: 650px) {
    font-size: 54px;
    width: 98%;
  }
  @media (max-width: 600px) {
    font-size: 48px;
    width: 90%;
    margin: 0 auto;
  }
`;
