import "./App.css";
import Header from "./Components/Header/Header";
import About from "./Pages/About/About";
import Homepage from "./Pages/Homepage/Homepage";
import Skills from "./Pages/Skills/Skills";
import MyPortfolio from "./Pages/MyProjects/MyPortfolio";
import Contact from "./Pages/Contact/Contact";
import { ThemeProvider } from "styled-components";
import { DarkTheme, LightTheme } from "./Data/Theme";
import { useEffect, useState } from "react";
import { ToggleDarkMode } from "./Components/Header/styles";
import { useDispatch } from "react-redux";
import { setDarkMode, setLevelColor } from "./Redux/LevelColor";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
function App() {
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [theme, setTheme] = useState(LightTheme);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setLevelColor(LightTheme.secondary_color));
    if (localStorage.getItem("dark_theme") != null) {
      const dark_theme = localStorage.getItem("dark_theme");
      if (dark_theme) {
        setIsDarkMode(true);
        changeTheme();
        dispatch(setLevelColor(DarkTheme.secondary_color));
      }
    }
  }, []);
  function changeTheme() {
    if (isDarkMode) {
      setTheme(LightTheme);
      setIsDarkMode(false);
      dispatch(setDarkMode(false));
      dispatch(setLevelColor(LightTheme.secondary_color));
      if (localStorage.getItem("dark_theme") != null) {
        localStorage.clear();
      }
    } else {
      setTheme(DarkTheme);
      setIsDarkMode(true);
      dispatch(setDarkMode(true));
      localStorage.setItem("dark_theme", true);

      dispatch(setLevelColor(DarkTheme.secondary_color));
    }
  }
  return (
    <div className="App">
      <ToggleDarkMode
        color={isDarkMode ? "white" : "black"}
        size={40}
        onClick={changeTheme}
      />
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />

      <ThemeProvider theme={theme}>
        <Header />
        <Homepage />
        <About />
        <Skills />
        <MyPortfolio />
        <Contact />
      </ThemeProvider>
    </div>
  );
}

export default App;
