import React from "react";
import ProjectCard from "../../Components/ProjectCard/ProjectCard";
import { myProjects } from "../../Data/Projects";
import { Container, ProjectsContainer, Title } from "./styles";

export default function MyPortfolio() {
  return (
    <Container id="portfolio">
      <Title>PORTFOLIO</Title>
      <ProjectsContainer>
        {myProjects.map((element, key) => (
          <ProjectCard props={element} key={key} />
        ))}
      </ProjectsContainer>
    </Container>
  );
}
