import styled from "styled-components";

export const DescDiv = styled.div`
  text-align: center;
  transition: all ease-out 0.5s;
  position: absolute;
  transition: all top 1s;
  left: 0;
  right: 0;
  top: 300px;
  /* &:hover {
    top: 20%;
  } */
`;
export const Card = styled.div`
  width: 22%;
  height: 510px;
  background-color: ${(props) => props.theme.project_card_color};
  margin: 20px 20px;
  border-radius: 15px;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  box-shadow: -1px 3px 14px -6px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: -1px 3px 14px -6px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: -1px 3px 14px -6px rgba(0, 0, 0, 0.75);
  transition: all ease-in 0.7s;
  border: 1px solid rgb(255, 255, 255, 0.1);
  /* &:hover {
    height: 700px;
  } */

  @media (max-width: 1800px) {
    width: 24%;
  }
  @media (max-width: 1650px) {
    width: 26%;
  }
  @media (max-width: 1550px) {
    width: 30%;
  }

  @media (max-width: 1350px) {
    width: 35%;
  }
  @media (max-width: 1150px) {
    width: 60%;
  }
  @media (max-width: 950px) {
    margin: 20px auto;
    width: 70%;
  }
  @media (max-width: 850px) {
    width: 80%;
  }
  @media (max-width: 750px) {
    width: 90%;
  }
  @media (max-width: 630px) {
    width: 95%;
  }

  @media (max-width: 416px) {
    height: 520px;
  }
`;

export const ImageDiv = styled.div`
  width: 100%;
  overflow: hidden;
  height: 320px;
  /* margin: auto; */
  text-align: center;
`;

export const Image = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
  transition: all ease-out 0.8s;
  &:hover {
    transform: scale(1.1);
  }
`;

export const ProjectTitle = styled.span`
  display: block;
  color: ${(props) => props.theme.project_desc_color};
  font-size: 26px;
  letter-spacing: 1px;
  margin-top: 24px;
  font-family: "Railway";
  font-weight: 600;
`;

export const DevelopedIn = styled.span`
  letter-spacing: 1px;
  font-family: "Montseratt";
  margin: 0 0;
  font-size: 14px;
  white-space: pre-line;
  color: ${(props) => props.theme.languages_color};
  transition: all 0.5s;
`;
export const Description = styled.p`
  color: ${(props) => props.theme.project_desc_color};
  padding: 5px 10px;
  letter-spacing: 1px;
  height: 65px;
  margin-top: 10px;
  font-family: "Montseratt";
  font-size: 14px;
  white-space: pre-line;
`;

export const CheckProjectButton = styled.button`
  padding: 12px 25px;
  background-color: ${(props) => props.theme.secondary_color};
  font-size: larger;
  letter-spacing: 1px;
  cursor: pointer;
  border-radius: 5px;
  position: relative;
  overflow: hidden;
  border: 0.1px solid rgb(0, 0, 0, 0.1);
  z-index: 5;
  transition: all 0.3s ease-in-out;

  &:hover {
    background-color: transparent;
    border: 1px solid ${(props) => props.theme.secondary_color};
    color: ${(props) => props.theme.secondary_color};
  }
  /* &::after {
    content: "";
    position: absolute;
    right: 0;
    height: 100%;
    width: 0%;
    top: 0;
    background-color: ${(props) => props.theme.CV_hover};
    transition: all ease-in 0.5s;
    z-index: -2;
  }

  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 0%;
    height: 100%;
    background-color: ${(props) => props.theme.CV_hover};
    transition: all ease-in 0.5s;
    z-index: -2;
  } */
  &:hover::after {
    width: 100%;
  }
  &:hover::before {
    width: 100%;
  }
`;
