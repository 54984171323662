import React from "react";
import {
  Card,
  CheckProjectButton,
  DescDiv,
  Description,
  DevelopedIn,
  Image,
  ImageDiv,
  ProjectTitle,
} from "./styles";
import Stam_Image from "../../images/ProjImage1.png";
export default function ProjectCard(props) {
  return (
    <Card>
      <ImageDiv>
        <Image src={props.props.image} />
      </ImageDiv>
      <DescDiv>
        <ProjectTitle>{props.props.name}</ProjectTitle>
        <Description>
          {props.props.description}
          <DevelopedIn>{props.props.languages}</DevelopedIn>
        </Description>
        <CheckProjectButton
          onClick={() => {
            window.open(props.props.link, "_blank");
          }}
        >
          View Project
        </CheckProjectButton>
      </DescDiv>
    </Card>
  );
}
