import styled from "styled-components";

export const Container = styled.div`
  position: absolute;
  top: 20%;
  left: 2%;
  z-index: 10;
`;
export const Burger = styled.div`
  position: relative;
  left: 2%;
  top: 20%;
  cursor: pointer;
`;

export const CloseX = styled.div`
  position: absolute;
  color: ${(props) => props.theme.font_color};
  font-size: 40px;
  font-family: "Montseratt";
  font-weight: 200;
  margin-top: 0;
  top: -14px;
`;
export const TopLine = styled.div`
  width: 30px;
  height: 2px;
  background-color: ${(props) => props.theme.font_color};
  position: absolute;
`;
export const MiddleLine = styled.div`
  width: 30px;
  height: 2px;
  background-color: ${(props) => props.theme.font_color};
  position: absolute;
  top: 10px;
`;
export const BottomLine = styled.div`
  width: 30px;
  height: 2px;
  background-color: ${(props) => props.theme.font_color};
  position: absolute;
  top: 20px;
`;
