import { createSlice } from "@reduxjs/toolkit";

export const LevelColorSlice = createSlice({
  name: "levelColor",
  initialState: {
    value: "#42b883",
    darkMode: false,
    isBurgerMenuClicked: false,
  },
  reducers: {
    setLevelColor: (state, action) => {
      state.value = action.payload;
    },
    setDarkMode: (state, action) => {
      state.darkMode = action.payload;
    },
    setBurgerMenuClicked: (state, action) => {
      state.isBurgerMenuClicked = action.payload;
    },
  },
});

export const { setLevelColor, setDarkMode, setBurgerMenuClicked } =
  LevelColorSlice.actions;
export default LevelColorSlice.reducer;
