import { HiOutlineLightBulb } from "react-icons/hi";
import styled from "styled-components";

export const Navbar = styled.div`
  text-align: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* position: sticky; */
  height: 50px;
  z-index: 6;
`;

export const Nav = styled.ul`
  list-style: none;
`;

export const NavElement = styled.li`
  display: inline;
`;

export const NavLink = styled.li`
  @import url("https://fonts.googleapis.com/css2?family=Pangolin&display=swap");
  text-decoration: none;
  padding: 10px 35px;
  display: inline;
  color: ${(props) => props.theme.header_color};
  font-size: larger;
  transition: all 0.5s;
  font-family: "Railway";
  font-weight: 400;
  /* cursor: pointer; */
  &:hover {
    color: ${(props) => props.theme.secondary_color};
  }
`;

export const ToggleDarkMode = styled(HiOutlineLightBulb)`
  transition: all 0.5s;
  position: fixed;
  cursor: pointer;
  z-index: 7;
  color: white;
  right: 10%;
  top: 3px;
  transition: all 0.3s;

  &:hover {
    color: ${(props) => props.theme.secondary_color};
  }

  @media (max-width: 600px) {
    right: 1%;
  }
`;
