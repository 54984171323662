import styled from "styled-components";

export const LogoContainer = styled.div`
  height: 50px;
  width: 150px;
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: center;
  left: 10%;
  font-size: x-large;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  overflow: hidden;
  z-index: 9;

  @media (max-width: 1100px) {
    margin: auto;
    width: 100%;
    justify-content: center;
    left: 0;
  }
`;

export const FirstnameSpan = styled.span`
  display: block;
  position: absolute;
  transition: all 0.5s;
  font-family: "DancingScript";
  font-size: 26px;

  @media (max-width: 1100px) {
    font-size: 28px;
  }
`;

export const LastnameSpan1 = styled.span`
  display: inline-block;
  transform-origin: center;
  transform: translate(0px, 110px) rotate(45deg);
  transition: all 0.5s;
`;

export const LastnameSpan2 = styled.span`
  display: inline-block;
  transform-origin: right center;
  transform: translate(0px, -110px) rotate(-45deg);
  transition: all 0.5s;
  color: ${(props) => props.theme.secondary_color};
`;
export const Logo = styled.div`
  color: ${(props) => props.theme.font_color};
  cursor: pointer;
  letter-spacing: 1px;
  &:hover ${FirstnameSpan} {
    color: transparent;
  }

  &:hover ${LastnameSpan1} {
    transform: translate(0px, 0) rotate(0deg);
  }

  &:hover ${LastnameSpan2} {
    transform: translate(0px, 0) rotate(0);
  }
`;
