import React from "react";
import { SiAboutdotme } from "react-icons/si";
import { IoMdHome } from "react-icons/io";
import { Container, Icon, Line, Menu, Ref } from "./styles";
import { MdDesignServices, MdOutlineMail } from "react-icons/md";
import { GoProject } from "react-icons/go";
import { useDispatch } from "react-redux";
import { setBurgerMenuClicked } from "../../Redux/LevelColor";
import { Link } from "react-scroll";

export default function DropDownMenu(props) {
  const dispatch = useDispatch();

  function scrollAndCloseMenu() {
    dispatch(setBurgerMenuClicked(false));
  }
  return (
    <Container>
      <Menu>
        <Ref>
          <Link onClick={scrollAndCloseMenu} smooth spy to="homepage">
            <Icon>
              <IoMdHome />
            </Icon>
            HOME
          </Link>
        </Ref>
        <Line />
        <Ref>
          <Link onClick={scrollAndCloseMenu} smooth spy to="about">
            <Icon>
              <SiAboutdotme />
            </Icon>
            ABOUT
          </Link>
        </Ref>
        <Line />

        <Ref>
          <Link onClick={scrollAndCloseMenu} smooth spy to="skills">
            <Icon>
              <MdDesignServices />
            </Icon>
            SKILLS
          </Link>
        </Ref>
        <Line />

        <Ref>
          <Link onClick={scrollAndCloseMenu} smooth spy to="portfolio">
            <Icon>
              <GoProject />
            </Icon>
            PORTFOLIO
          </Link>
        </Ref>
        <Line />

        <Ref>
          <Link onClick={scrollAndCloseMenu} smooth spy to="contact">
            <Icon>
              <MdOutlineMail />
            </Icon>
            CONTACT
          </Link>
        </Ref>
      </Menu>
    </Container>
  );
}
