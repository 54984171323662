import React, { useRef, useState } from "react";
import {
  Container,
  EmailField,
  Footer,
  FormContainer,
  FormTitle,
  FullnameField,
  GitHubLogo,
  LeftContainer,
  LineInBetween,
  LinkedInLogo,
  MailLogo,
  MessageField,
  MyStyledLabel,
  Section,
  SendMessageButton,
  Signature,
  Text,
  Text2,
  ThanksSection,
  ThanksText,
  Title,
} from "./styles";
import { toast } from "react-toastify";
import emailjs from "@emailjs/browser";
import BeatLoader from "react-spinners/BeatLoader";
import { useSelector } from "react-redux";
export default function Contact() {
  const form = useRef();
  const isDarkMode = useSelector((state) => state.levelColor.darkMode);
  const [isButtonClicked, setIsButtonClicked] = useState(false);
  const [showThanksMessage, setThanksMessage] = useState(false);
  const override = {
    display: "block",
    margin: "20px auto",
    borderColor: "red",
    padding: "0 auto",
    textAlign: "center",
  };
  const sendEmail = (e) => {
    e.preventDefault();
    let name = e.target.elements["user_name"].value;
    let email = e.target.elements["user_email"].value;
    let message = e.target.elements["message"].value;

    if (name.length === 0 || email.length === 0 || message.length === 0) {
      toast.error("Please fill all the fields.");
    } else if (!name.match(/^[a-z ,.'-]+$/i)) {
      toast.error("Name should not contain digits or signs!");
    } else if (!email.match(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i)) {
      toast.error("Invalid email!");
    } else {
      setIsButtonClicked(!isButtonClicked);
      emailjs
        .sendForm(
          process.env.REACT_APP_SERVICE_ID,
          process.env.REACT_APP_TEMPLATE_ID,
          form.current,
          process.env.REACT_APP_USER_PUBLIC_KEY
        )
        .then(
          (result) => {
            setThanksMessage(true);
            e.target.reset();
          },
          (error) => {
            console.log(error.text);
          }
        );
    }
  };

  const openInNewTab = (url) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };
  const onClickUrl = (url) => {
    return () => openInNewTab(url);
  };

  const openWindowsMail = () => {
    window.location.href = `mailto:${"mohamdpal1@gmail.com"}`;
  };
  return (
    <Container id="contact">
      <Title>Contact</Title>
      <LineInBetween />
      {showThanksMessage && (
        <ThanksSection>
          <ThanksText>
            <MyStyledLabel>Thank you</MyStyledLabel> for reaching out.
            <br />I will get back to you <MyStyledLabel>soon</MyStyledLabel>.
            <Signature>
              Mohamed <MyStyledLabel primary>Agbaria</MyStyledLabel>
            </Signature>
          </ThanksText>
        </ThanksSection>
      )}
      {!showThanksMessage && (
        <Section>
          <LeftContainer>
            <Text>
              Want to get in <MyStyledLabel> touch</MyStyledLabel> with me?
              <Text2>
                {" "}
                Fill the <MyStyledLabel>form</MyStyledLabel>.
              </Text2>
            </Text>
          </LeftContainer>
          <FormContainer>
            <FormTitle>Send me a message.</FormTitle>
            <form ref={form} onSubmit={sendEmail}>
              <FullnameField
                name="user_name"
                type="text"
                placeholder="full name"
              />
              <EmailField
                name="user_email"
                type="email"
                placeholder="email address"
              />
              <MessageField name="message" placeholder="message..." />
              {!isButtonClicked && <SendMessageButton>Send</SendMessageButton>}
              {isButtonClicked && (
                <BeatLoader
                  color={isDarkMode ? "#42b883" : "#EE3559"}
                  loading="true"
                  cssOverride={override}
                  size={30}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                />
              )}
            </form>
          </FormContainer>
        </Section>
      )}

      <LineInBetween />
      <Footer>
        <GitHubLogo
          onClick={onClickUrl("https://github.com/MohamedKamal710")}
          size={30}
        />
        <LinkedInLogo
          onClick={onClickUrl(
            "https://www.linkedin.com/in/mohamed-agbaria-b39584203/"
          )}
          size={30}
        />
        <MailLogo onClick={openWindowsMail} size={30} />
      </Footer>
    </Container>
  );
}
