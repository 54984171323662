import styled from "styled-components";

export const Divider = styled.div`
  margin: 0 auto;
  text-align: center;
  margin: 10px 40px;
  width: 40%;
  border: 1px solid ${(props) => props.theme.font_color};
  border-radius: 15px;
  background-color: ${(props) => props.theme.skills_containers_color};
  transition: all 1s;

  overflow: hidden;
  &:hover {
    background-color: ${(props) => props.theme.skills_containers_hover_color};
  }

  @media (max-width: 1000px) {
    width: 70%;
    margin: 3rem 0;
  }

  @media (max-width: 580px) {
    width: 90%;
  }
  @media (max-width: 390px) {
    width: 100%;
  }
`;
export const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 5% 8%;
  justify-content: flex-start;

  @media (max-width: 500px) {
    padding: 5% 2%;
  }

  @media (max-width: 385px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  @media (max-width: 420px) {
    padding: 5% 1%;
  }
`;

export const Skill = styled.div`
  width: 47%;
  margin: 20px 5px;
  transition: all ease-in 0.5s;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: default;
  &:hover {
    transform: scale(1.1);
  }
`;

export const Div1 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 10px;
`;
export const Language = styled.span`
  color: ${(props) => props.theme.secondary_color};
  display: block;
  font-size: 22px;

  @media (max-width: 420px) {
    font-size: 20px;
  }
`;

export const Level = styled.span`
  color: ${(props) => props.theme.skills_level_color};
`;

export const Title = styled.p`
  color: ${(props) => props.theme.skills_title_color};
  font-size: 30px;
  font-weight: 100;
  letter-spacing: 5px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  border-bottom: 1px solid
    ${(props) => props.theme.skills_title_underline_color};
  border-width: auto;
  display: inline-block;
  margin: 10px auto;
`;
