import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-scroll";
import SetWindowSize from "../../Helper/SetWindowSize";

import BurgetMenu from "../BurgerMenu/BurgetMenu";
import DropDownMenu from "../DropDownMenu/DropDownMenu";
import MyLogo from "../MyLogo/MyLogo";
import { Nav, Navbar, NavLink } from "./styles";

export default function Header() {
  const windowSize = SetWindowSize();
  const isBurgerClicked = useSelector(
    (state) => state.levelColor.isBurgerMenuClicked
  );

  return (
    <div>
      <Navbar>
        {isBurgerClicked && windowSize < 1200 && <DropDownMenu />}
        {windowSize < 1200 && <BurgetMenu />}
        <MyLogo />

        {windowSize > 1100 && (
          <Nav>
            <NavLink>
              <Link
                style={{ cursor: "pointer" }}
                activeClass="active"
                smooth
                spy
                to="homepage"
                activeStyle={{
                  fontWeight: 800,
                  textDecoration: "none",
                }}
              >
                Home
              </Link>
            </NavLink>

            <NavLink>
              <Link
                style={{ cursor: "pointer" }}
                activeClass="active"
                smooth
                spy
                to="about"
                activeStyle={{
                  fontWeight: 800,
                  textDecoration: "none",
                }}
              >
                About
              </Link>
            </NavLink>
            <NavLink>
              <Link
                style={{ cursor: "pointer" }}
                activeClass="active"
                smooth
                spy
                to="skills"
                activeStyle={{
                  fontWeight: 800,
                  textDecoration: "none",
                }}
              >
                Skills
              </Link>
            </NavLink>

            <NavLink>
              <Link
                style={{ cursor: "pointer" }}
                activeClass="active"
                smooth
                spy
                to="portfolio"
                activeStyle={{
                  fontWeight: 800,
                  textDecoration: "none",
                }}
              >
                Portfolio
              </Link>
            </NavLink>

            <NavLink>
              <Link
                style={{ cursor: "pointer" }}
                activeClass="active"
                smooth
                spy
                to="contact"
                activeStyle={{
                  fontWeight: 800,
                  textDecoration: "none",
                }}
              >
                Contact
              </Link>
            </NavLink>
          </Nav>
        )}
      </Navbar>
    </div>
  );
}
