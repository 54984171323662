import ProjImage1 from "../images/ProjImage1.png";
import ProjImage2 from "../images/ProjImage2.png";
import ProjImage3 from "../images/ProjImage3.png";

export const myProjects = [
  {
    name: "Epicure",
    description:
      "Responsive online ordering website for the top Tel-Aviv restaurants.\n",
    languages: "[ React , Nodejs , MongoDB]",
    image: ProjImage1,
    link: "https://epicure-mohamed-agb.herokuapp.com/",
  },
  {
    name: "Dolce Creama",
    description:
      "Online ordering website for supplying raw materials to various types of restaurants, coffee shops, and ice cream shops.\n",
    image: ProjImage2,
    languages: "[ PHP , Mysql ]",
    link: "https://dolce-creama.herokuapp.com/",
  },
  {
    name: "Petek",
    description:
      "Family buying list website with features for managing family members, creating and tracking to-buy lists, and more.\n",
    image: ProjImage3,
    languages: "[ PHP , Mysql ]",

    link: "https://family-list.herokuapp.com/",
  },
];
