import React, { useEffect } from "react";
import Typed from "typed.js";
import {
  AnimatedText,
  BackImage,
  Container,
  CVDownloadButton,
  HelloText,
  Icon,
  Icons,
  IntroDesc,
  Introduction,
  IntroText,
  LeftContainer,
  Line,
  MyImage,
  RightContainer,
  StyledFaGithub,
  StyledLinkedin,
} from "./styles";
import my_cv from "../Homepage/Mohamed_Agbaria_CV.pdf";
import Back1 from "../../images/back1.png";
import Back2 from "../../images/back2.png";
import MyPict from "../../images/Man.png";
import { useSelector } from "react-redux";
export default function Homepage() {
  const dark = useSelector((state) => state.levelColor.darkMode);
  useEffect(() => {
    var typed = new Typed(".auto-text", {
      strings: ["Mohamed Agbaria", "Software Developer"],
      cursorChar: "|",
      typeSpeed: 150,
      backSpeed: 150,
      backDelay: 1500,
      fadeOut: true,
      fadeOutClass: "typed-fade-out",

      loop: true,
    });
  }, []);

  const openInNewTab = (url) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };
  const onClickUrl = (url) => {
    return () => openInNewTab(url);
  };
  return (
    <Container id="homepage">
      <LeftContainer>
        <Introduction>
          <HelloText>Hello there</HelloText>
          <IntroText>
            I'm <AnimatedText className="auto-text"></AnimatedText>
          </IntroText>
          <IntroDesc>
            A very ambitious software developer, creative, out-of-the-box
            thinker, and passionate about innovation.
          </IntroDesc>
        </Introduction>
        <a href={my_cv} download>
          <CVDownloadButton>DOWNLOAD CV</CVDownloadButton>
        </a>

        <Icons>
          <Line />

          <Icon onClick={onClickUrl("https://github.com/MohamedKamal710")}>
            <StyledFaGithub size={30} />
          </Icon>
          <Icon
            onClick={onClickUrl(
              "https://www.linkedin.com/in/mohamed-agbaria-b39584203/"
            )}
          >
            <StyledLinkedin size={30} />
          </Icon>
        </Icons>
      </LeftContainer>
      <RightContainer>
        <BackImage src={dark ? Back1 : Back2} alt="back" />
        <MyImage src={MyPict} alt="myself" />
      </RightContainer>
    </Container>
  );
}
