import { FaGithub, FaLinkedin } from "react-icons/fa";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  color: white;
  height: 100vh;
  background: ${(props) => props.theme.homepage_color};
  transition: all 1s ease;
  -webkit-transition: 1s ease;
  -moz-transition: 1s ease;
  -ms-transition: 1s ease;
  -o-transition: color 1s ease;
  position: relative;
  overflow: hidden;

  @media (max-width: 1300px) {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  @media (max-width: 1200px) {
    flex-direction: column;
    flex-wrap: wrap;
    height: auto;
    min-height: 100vh;
    text-align: center;
  }
`;

export const RightContainer = styled.div`
  width: 40%;
  text-align: left;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  height: 100%;

  @media (max-width: 1300px) {
    width: 50%;
  }

  @media (max-width: 1200px) {
    height: 600px;
    margin-top: 5rem;
  }

  @media (max-width: 1000px) {
    height: 550px;
  }
  @media (max-width: 800px) {
    height: 480px;
  }

  @media (max-width: 530px) {
    height: 440px;
  }

  @media (max-width: 480px) {
    height: 400px;
  }
  @media (max-width: 400px) {
    height: 360px;
  }
`;

export const HelloText = styled.span`
  display: flex;
  flex-direction: row;
  width: 125px;
  font-size: 18px;
  font-family: "Montseratt";
  &::before {
    content: "";
    flex: 1;
    border-bottom: 1px solid;
    margin: auto;
    margin-right: 10px;
  }

  @media (max-width: 1200px) {
    /* width: 100%; */
    text-align: center;
    display: block;
    width: 100%;
  }

  @media (max-width: 900px) {
    font-size: 24px;
    margin-bottom: 2rem;
  }
  @media (max-width: 440px) {
    font-size: 21px;
  }
`;

export const Introduction = styled.div`
  text-align: left;
  width: 100%;
  /* margin-top: 50%; */

  @media (max-width: 1200px) {
    align-items: center;
    margin: 0 auto;
    text-align: center;
  }
`;
export const LeftContainer = styled.div`
  width: 40%;
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: ${(props) => props.theme.font_color};
  transition: all 0.8s;
  @media (max-width: 1300px) {
    width: 50%;
  }

  @media (max-width: 1200px) {
    height: 600px;
    margin-top: 2rem;
    width: 100%;
    align-items: center;
  }
`;

export const IntroText = styled.p`
  font-size: 50px;
  margin: 0;
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
  letter-spacing: 1px;
  transition: all 0.8s;

  @media (max-width: 1200px) {
    display: inline-block;
    /* width: 100%; */
    text-align: center;
  }
  @media (max-width: 650px) {
    font-size: 40px;
  }
  @media (max-width: 580px) {
    font-size: 38px;
  }
  @media (max-width: 500px) {
    font-size: 34px;
  }
  @media (max-width: 440px) {
    font-size: 30px;
  }
`;

export const AnimatedText = styled.span`
  color: ${(props) => props.theme.secondary_color};
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 600;
  letter-spacing: 1px;
  transition: all 0.8s;

  @media (max-width: 1200px) {
    display: inline-block;
    text-align: center;
  }
`;

export const IntroDesc = styled.p`
  letter-spacing: 1px;
  color: rgb(255, 255, 255, 0.8);
  font-weight: 200;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  width: 500px;
  color: ${(props) => props.theme.font_color};
  transition: all 0.8s;

  @media (max-width: 1200px) {
    width: 50%;
    text-align: center;
    margin: 0 auto;
    margin-top: 1rem;
  }

  @media (max-width: 900px) {
    font-size: 21px;
    margin-top: 2rem;
  }
  @media (max-width: 600px) {
    width: 70%;
  }
  @media (max-width: 440px) {
    font-size: 19px;
  }
`;

export const BackImage = styled.img`
  width: 140%;
  position: absolute;
  z-index: 1;
  transform: rotate(-10deg);

  @media (max-width: 1200px) {
    position: relative;
  }

  @media (max-width: 1000px) {
    width: 160%;
  }

  @media (max-width: 800px) {
    width: 190%;
  }
  @media (max-width: 600px) {
    width: 220%;
  }
  @media (max-width: 480px) {
    width: 240%;
  }
  /* bottom: -100px; */
`;

export const MyImage = styled.img`
  width: 70%;
  z-index: 5;
  position: absolute;
  bottom: 0;

  @media (max-width: 1500px) {
    width: 80%;
  }

  @media (max-width: 1000px) {
    width: 90%;
  }
  @media (max-width: 800px) {
    width: 100%;
  }
  @media (max-width: 600px) {
    width: 120%;
  }
  @media (max-width: 480px) {
    width: 140%;
  }
`;

export const CVDownloadButton = styled.button`
  padding: 14px 30px;
  font-size: large;
  letter-spacing: 1px;
  background-color: ${(props) => props.theme.secondary_color};
  border-radius: 5px;
  border: none;
  margin-top: 50px;
  cursor: pointer;
  z-index: 5;
  position: relative;
  overflow: hidden;
  margin-left: 17%;
  max-width: 220px;
  transition: all 0.8s;

  &::before {
    content: "";
    width: 0%;
    position: absolute;
    height: 100%;
    left: 0;
    top: 0;
    z-index: -2;
    transition: all ease-in 0.4s;
    background-color: ${(props) => props.theme.CV_hover};
  }

  &::after {
    content: "";
    position: absolute;
    right: 0;
    height: 100%;
    width: 0%;
    top: 0;
    background-color: ${(props) => props.theme.CV_hover};
    transition: all ease-in 0.4s;
    z-index: -2;
  }

  &:hover {
    &::before {
      width: 100%;
    }

    &::after {
      width: 100%;
    }
  }

  @media (max-width: 1200px) {
    margin-left: 0;
  }
`;

export const Line = styled.hr`
  width: 200px;
  border-color: ${(props) => props.theme.line_color};
  margin-left: -35%;
  margin-bottom: 15px;
  transition: all 0.8s;

  @media (max-width: 1200px) {
    margin-left: -18%;
  }
`;
export const Icons = styled.div`
  position: absolute;
  bottom: 15%;
  width: 150px;
  margin-left: 25%;

  @media (max-width: 1200px) {
    margin-left: 0;
    text-align: center;
    bottom: 10%;
  }
  @media (max-width: 900px) {
    bottom: 5%;
  }
`;

export const StyledFaGithub = styled(FaGithub)`
  transition: all 0.4s;
  cursor: pointer;
  &:hover {
    color: ${(props) => props.theme.secondary_color};
    transform: scale(1.1);
  }
`;

export const StyledLinkedin = styled(FaLinkedin)`
  transition: all 0.4s;
  cursor: pointer;
  &:hover {
    color: ${(props) => props.theme.secondary_color};
    transform: scale(1.1);
  }
`;
export const Icon = styled.i`
  cursor: pointer;
  margin-right: 30px;

  @media (max-width: 1200px) {
    margin-left: 10px;
    margin-right: 10px;
  }
`;
