export const DarkTheme = {
  font_color: "white",
  homepage_color:
    "linear-gradient(210deg,rgba(31, 49, 68, 1) 0%,rgba(37, 65, 94, 1) 49%,rgba(31, 49, 68, 1) 100%)",
  secondary_color: "#42b883",
  CV_hover: "#16f18f",
  Menu_color: "rgba(31, 49, 68, 1)",
  line_color: "#ffffff1f",
  header_color: "white",
  contact_page_color: "#293966",
  skills_page_color: "rgba(38, 50, 64, 1)",
  HR_color: "rgb(255, 255, 255, 0.05)",
  about_page_color:
    "linear-gradient(180deg,rgba(38, 50, 64, 1) 0%,rgba(41, 75, 110, 1) 50%,rgba(38, 50, 64, 1) 100%);",
  portfolio_color: "#2c4255",
  about_firstBackgroundImage: "#208f5d",
  about_secondBackgroundImage: "#1a6644",
  skills_containers_color: "#293849",
  skills_level_color: "#ffffffa2",
  skills_title_color: "#ffffffd6",
  skills_title_underline_color: "#ffffff4e",
  skills_containers_hover_color: "#55698055",
  project_card_color: "#1c3447",
  project_desc_color: "#ffffffc0",
  contact_button_color:
    "radial-gradient(circle,rgba(66, 184, 131, 1) 0%,rgba(52, 209, 139, 1) 50%,rgba(66, 184, 131, 1) 100%);",
  X_color: "#327959",
  square_color: "#2cd187",
  fst_triangle_color: "#52df9f",
  scnd_triangle_color: "#77ecb8",
  fst_rect_color: "#179b60",
  scnd_rect_color: "#08884e",
  side_circle_color: "white",
  languages_color: "#14ad68a9",
};

export const LightTheme = {
  homepage_color:
    "linear-gradient(180deg, rgba(232,232,232,1) 0%, rgba(237,242,240,1) 50%, rgba(232,232,232,1) 100%)",
  secondary_color: "#EE3559",
  font_color: "black",
  line_color: "black",
  Menu_color: "#f6f6f6",
  CV_hover: "#FF3F64",
  header_color: "black",
  contact_page_color: "#EDEDED",
  skills_page_color: "rgba(221,220,220,1)",
  HR_color: "rgb(0, 0, 0, 0.05)",

  about_page_color:
    "linear-gradient(0deg, rgba(221,220,220,1) 0%, rgba(231,232,232,1) 50%, rgba(221,220,220,1) 100%)",
  portfolio_color: "#E1E1E1",
  about_firstBackgroundImage: "#D53756",
  about_secondBackgroundImage: "#BD2F4B",
  skills_containers_color: "#D2D1D1",
  skills_level_color: "rgb(0,0,0,0.7)",
  skills_title_color: "#rgb(0,0,0,0.8)",
  skills_title_underline_color: "rgb(0,0,0,0.3)",
  skills_containers_hover_color: "#E2E1E1",
  project_card_color: "#DBDBDB",
  project_desc_color: "#000000c0",
  contact_button_color:
    "radial-gradient(circle, rgba(238,53,89,1) 0%, rgba(212,46,78,1) 50%, rgba(238,53,89,1) 100%);",
  X_color: "#C83450",
  square_color: "#EC385A",
  fst_triangle_color: "#d51036",
  scnd_triangle_color: "#f45c79",
  fst_rect_color: "#e86c83",
  scnd_rect_color: "#a30e2b",
  side_circle_color: "#202020",
  languages_color: "#b90328b5",
};
