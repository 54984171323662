import React from "react";
import { Link } from "react-scroll";
import {
  FirstnameSpan,
  LastnameSpan1,
  LastnameSpan2,
  Logo,
  LogoContainer,
} from "./styles";

export default function MyLogo() {
  return (
    <LogoContainer>
      <Link style={{ fontWeight: "400" }} smooth spy to="homepage">
        <Logo>
          <FirstnameSpan>Mohamed</FirstnameSpan>{" "}
          <LastnameSpan1>AGB</LastnameSpan1>
          <LastnameSpan2>ARIA</LastnameSpan2>
        </Logo>
      </Link>
    </LogoContainer>
  );
}
