import React from "react";
import { ImageBackground, MyPic, PictureContainer } from "./styles";
import My_Pict from "../../images/Man2.png";
export default function MyImage() {
  return (
    <div>
      <ImageBackground>
        <PictureContainer>
          <MyPic src={My_Pict} />
        </PictureContainer>
      </ImageBackground>
    </div>
  );
}
