import styled from "styled-components";

export const Container = styled.div`
  height: 100vh;
  background: ${(props) => props.theme.about_page_color};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 1100px) {
    height: auto;
    padding-top: 10%;
    padding-bottom: 3%;
  }
`;

export const AboutContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;

  @media (max-width: 1100px) {
    flex-direction: column-reverse;
    text-align: center;
    align-items: center;
  }
`;

export const LeftContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: relative;
  width: 40%;

  @media (max-width: 1300px) {
    width: 45%;
  }
`;

export const RightContent = styled.div`
  width: 40%;
  @media (max-width: 1300px) {
    width: 45%;
  }

  @media (max-width: 1100px) {
    margin: 0 auto;
    width: 60%;
  }
  @media (max-width: 650px) {
    width: 80%;
  }
  @media (max-width: 520px) {
    width: 90%;
  }
`;

export const AboutHeader = styled.p`
  display: flex;
  flex-direction: row;
  width: 160px;
  margin-top: 0;
  letter-spacing: 1px;
  font-family: "Railway";
  font-size: 36px;
  color: ${(props) => props.theme.font_color};
  transition: all 0.8s;
  &::before {
    content: "";
    flex: 1;
    border-bottom: 1px solid;
    margin: auto;
    margin-right: 10px;
  }

  @media (max-width: 1100px) {
    width: 100%;

    &::after {
      content: "";
      flex: 1;
      border-bottom: 1px solid;
      margin: auto;
      margin-left: 10px;
      color: #ffffff39;
    }

    &::before {
      color: #ffffff39;
    }
  }

  @media (max-width: 700px) {
    margin-top: 3rem;
  }
`;

export const AboutMeDesc = styled.p`
  color: ${(props) => props.theme.font_color};
  letter-spacing: 2px;
  font-size: 18px;
  line-height: 2rem;
  transition: all 0.8s;
  font-family: "Montseratt";

  @media (max-width: 1100px) {
    margin-bottom: 4rem;
  }
`;
