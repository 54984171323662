import React from "react";
import FrontendSkills from "../../Components/FrontendSkills/FrontendSkills";
import BackendSkills from "../../Components/BackendSkills/BackendSkills";
import { Container, FrontAndBackContainer, SkillsTitle } from "./styles";

export default function Skills() {
  return (
    <Container id="skills">
      <SkillsTitle>Skills</SkillsTitle>
      <FrontAndBackContainer>
        <FrontendSkills />
        <BackendSkills />
      </FrontAndBackContainer>
    </Container>
  );
}
